import React from 'react'
import classNames from 'classnames'
import Loader from 'react-loader-spinner'

const Button = ({
  type = 'button',
  className,
  disabled = false,
  loading = false,
  children,
  onClick = () => {}
}) => {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={classNames(
        'mt-6 block w-full focus:shadow-outline focus:outline-none text-white font-semibold leading-5 py-3 px-5 rounded transition-colors duration-150',
        { [`bg-blue-500 hover:bg-blue-600`]: !(disabled || loading) },
        {
          [`bg-blue-300 hover:bg-blue-300 cursor-not-allowed`]:
            disabled || loading,
        },
        className
      )}
      onClick={onClick}
    >
      {loading ? (
        <div className="flex justify-center">
          <div className="mr-2">
            <Loader type="Oval" color="#ffffff" height={20} width={20} />
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
