import React from 'react'

const TEXTS = {
  language: {
    en: 'Language',
    it: 'Lingua',
    fr: 'Langue',
  },
  title: {
    en: 'Platforms Vision Line Promo',
    it: 'Offerta lancio piattaforma Vision Line',
    fr: 'Promo plateformes Vision Line',
  },
  instructions: {
    en: `Register to receive our offer directly to your e-mail`,
    it: `Registrati per ricevere l'offerta via e-mail`,
    fr: `Inscrivez-vous pour recevoir notre offre directement à votre adresse mail`,
  },
  required: {
    en: 'Required',
    it: 'Richiesto',
    fr: 'Obligatoire',
  },
  company: {
    en: 'Company name',
    it: 'Nome azienda',
    fr: 'Enterprise',
  },
  city: {
    en: 'City',
    it: 'Città',
    fr: 'Ville',
  },
  zipCode: {
    en: 'Zip Code',
    it: 'CAP',
    fr: 'Code postal',
  },
  mobile: {
    en: 'Mobile phone number',
    it: 'Cellulare',
    fr: 'N° tél portable',
  },
  email: {
    en: 'Email address',
    it: 'Email',
    fr: 'Adresse mail',
  },
  send: {
    en: 'Sign up now',
    it: 'Invia registrazione',
    fr: 'Inscrivez-vous',
  },
  thankYou: {
    en: [`Thank you for your registration.`, `You will soon receive an email.`],
    it: [
      `Grazie per esserti registrato.`,
      `L'offerta lancio sarà immediatamente inviata al tuo indirizzo email.`,
    ],
    fr: [`Merci pour votre inscription.`, `Vous recevrez bientôt un e-mail.`],
  },
  error: {
    en: `An error occurred while sending your request`,
    it: `Si è verificato un errore durante l'invio della richiesta`,
    fr: `Une erreur s'est produite lors de l'envoi de votre demande`
  },
  privacy: {
    en: <p><b>Regulation (EU) 2016/679 (GDPR).</b> By ticking the box you are agreeing the treatment of your data for advertising on our products and services, as explained in our <a href="http://www.omcn.it/en/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700 hover:underline">Privacy Policy</a></p>,
    it: <p><b>Regolamento Ue 2016/679 (GDPR).</b> Spuntando la casella consente ad OMCN S.p.A. di trattare i suoi dati per inviarLe materiale promozionale ed informativo relativo ai nostri prodotti e servizi, come specificato nella <a href="http://www.omcn.it/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700 hover:underline">Informativa Privacy</a></p>,
    fr: <p><b>Règlement (UE) 2016/679(GDPR).</b> En cochant la case vous autorisez le traitement des données pour l’envoi de matériel promotionnel et d’information sur nos produits et services, comme indiqué dans notre <a href="http://www.omcn.it/fr/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700 hover:underline">Politique de Confidentialité</a></p>
  }
}

export default TEXTS
