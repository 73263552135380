import React from 'react'
import classNames from 'classnames'

const FormField = ({
  fieldName,
  type = 'text',
  label,
  register,
  validation,
  error,
  requiredText = 'Required'
}) => {
  return (
    <div className="mb-3">
      {label && (
        <label
          htmlFor={fieldName}
          className="block text-sm leading-5 font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <input
        id={fieldName}
        name={fieldName}
        ref={register(validation)}
        type={type}
        className={classNames(
          'form-input bg-gray-100 focus:bg-white w-full mt-1',
          { 'border-red-500 bg-pink-100 focus:border-red-500': error }
        )}
      />
      {error && <span className="text-sm text-red-500">{requiredText}</span>}
    </div>
  )
}

export default FormField
