import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import cogoToast from 'cogo-toast'
import ReactGA from 'react-ga'

import FormField from './FormField'
import Button from './Button'
import getUrlParameter from './getUrlParameter'
import TEXTS from './translations'
import logo from './images/logo_omcn.png'
import axios from 'axios'

const App = () => {
  const [language, setLanguage] = useState('en')
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const { register, handleSubmit, errors } = useForm()

  useEffect(() => {
    const lang = getUrlParameter('lang')
    if (lang) {
      setLanguage(lang)
    }
    // Initialize React GA and send PageView
    ReactGA.initialize('UA-4718638-3')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const sendForm = async (data) => {
    try {
      setLoading(true)
      await axios.post('https://api.promo.omcn.it/send-form', {
        ...data,
        language,
      })
      setSent(true)
      // Send ReactGA event
      ReactGA.event({
        category: 'OMCN Promo',
        action: 'Registration',
        label: language,
      })
    } catch {
      cogoToast.error(TEXTS.error[language])
    } finally {
      setLoading(false)
    }
  }

  const requiredText = TEXTS.required[language]

  return (
    <div className="app max-w-sm mx-auto pt-12 pb-20 px-6 text-gray-900 antialiased">
      <div className="flex flex-col justify-center items-center">
        <img src={logo} alt="OMCN" className="mb-3" />
        <h1 className="text-2xl font-semibold text-blue-700 md:text-blue-600 mb-5 text-center">
          {TEXTS.title[language]}
        </h1>
      </div>
      {!sent ? (
        <form onSubmit={handleSubmit(sendForm)}>
          <div className="mb-3">
            <label
              htmlFor="language"
              className="block text-sm leading-5 font-medium text-gray-700"
            >
              {TEXTS.language[language]}
            </label>
            <select
              className="form-select bg-gray-100 focus:bg-white w-full mt-1"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="it">Italiano</option>
              <option value="en">English</option>
              <option value="fr">Francais</option>
            </select>
          </div>
          <FormField
            fieldName="company"
            type="text"
            register={register}
            validation={{ required: true }}
            error={errors.company}
            label={TEXTS.company[language]}
            requiredText={requiredText}
          />
          <FormField
            fieldName="city"
            type="text"
            register={register}
            validation={{ required: true }}
            error={errors.city}
            label={TEXTS.city[language]}
            requiredText={requiredText}
          />
          <FormField
            fieldName="zipCode"
            type="text"
            register={register}
            validation={{ required: true }}
            error={errors.zipCode}
            label={TEXTS.zipCode[language]}
            requiredText={requiredText}
          />
          <FormField
            fieldName="mobile"
            type="text"
            register={register}
            validation={{ required: true }}
            error={errors.mobile}
            label={TEXTS.mobile[language]}
            requiredText={requiredText}
          />
          <FormField
            fieldName="email"
            type="email"
            register={register}
            validation={{ required: true }}
            error={errors.email}
            label={TEXTS.email[language]}
            requiredText={requiredText}
          />

          <div className="mb-4">
            <label className="flex items-start text-gray-600">
              <input
                className="form-checkbox"
                type="checkbox"
                name="privacy"
                ref={register({ required: true })}
              />
              <span className="ml-2 text-xs">{TEXTS.privacy[language]}</span>
            </label>
            {errors.privacy && (
              <span className="text-sm text-red-500 ml-6">{requiredText}</span>
            )}
          </div>

          <Button type="submit" loading={loading}>
            {TEXTS.send[language]}
          </Button>
        </form>
      ) : (
        <div className="flex flex-col items-center">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/9/92/Sport_car_rim.svg"
            className="spin-image mt-4 mb-6"
            alt="Wheel spinning"
          />
          {TEXTS.thankYou[language].map((paragraph, index) => (
            <p key={index} className="text-xl text-center text-gray-800">
              {paragraph}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

export default App
